<template>
    <div class="mobileTable scrolling">
        <searchBox v-model="queryParam.name" placeholder="请输入名称" @search="onSearch" />
        <div class="tableContainer">
            <s-table
                ref="table"
                :columns="columns"
                :scroll="{ x: '100%' }"
                :data="loadData"
                :alert="false"
                :showTools="false"
                :rowKey="(record) => record.id"
            >
                <span slot="draftId" slot-scope="text, record">
                    <div v-for="(o, i) in record.draftId" :key="i" class="my-item">
                        <a-tag class="tags flex0" color="blue">
                            <OpenData type="userName" :openid="o.userId" :corpid="o.corpId"></OpenData>
                        </a-tag>
                    </div>
                    <!-- <OpenData type="userName" :openid="record.draftId" :corpid="record.corpId"></OpenData> -->
                </span>
                <span slot="fileType" slot-scope="text">
                    {{ Filter(flow_file_type, text) }}
                </span>
                <span slot="acceptTime" slot-scope="text">
                    {{ text && text.substr(0, 10) }}
                </span>
                <span slot="urgencyDegree" slot-scope="text">
                    {{ Filter(flow_urgency_degree, text) }}
                </span>
                <span slot="secrecyDegree" slot-scope="text">
                    {{ Filter(flow_secrecy_degree, text) }}
                </span>
                <span slot="userId" slot-scope="text, record">
                    <OpenData type="userName" :openid="record.userId" :corpid="record.corpId"></OpenData>
                </span>
                <span slot="approvalStatus" slot-scope="text" :class="text != 2 ? 'fred' : ''">
                    {{ Filter(flow_approval_status, text) }}
                </span>
                <span slot="action" slot-scope="text, record">
                    <a class="bts" v-if="record.approvalStatus == 0" @click="edit(record)">修改</a>
                    <a class="bts" v-if="record.approvalStatus == 1 && [1].includes(approvalTab)" @click="senpi(record)"
                        >审批</a
                    >
                    <!-- <a class="bts" v-if="record.approvalStatus == 2">加签</a> -->
                    <a class="bts" @click="see(record)">查看</a>
                </span>
            </s-table>
        </div>
    </div>
</template>
<script>
import {
    delDocumentBill,
    documentBillAuditedPage,
    documentBillAuditPage,
} from '@/api/modular/fileStream/documentManage'
import { STable } from '@/components'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import minxin from '../components/minxin'
import searchBox from '../components/searchBox'
import { endColumns, isFalse, satrtColumns } from './config'
export default {
    components: {
        STable,
        searchBox,
        OpenData,
    },
    mixins: [minxin],
    props: {
        approvalTab: {
            type: Number,
            default: -1,
        },
    },
    computed: {
        columns() {
            let arr = []
            arr = [...satrtColumns, ...endColumns]
            if (this.approvalTab === 1) {
                arr = [
                    ...satrtColumns,
                    { title: '审批人', width: '150px', dataIndex: 'userId', scopedSlots: { customRender: 'userId' } },
                    ...endColumns,
                ]
            }
            return arr
        },
    },
    watch: {
        approvalTab: {
            handler(val) {
                if (!isFalse(val)) {
                    this.queryParam.approvalTab = val
                    this.$refs.table && this.$refs.table.refresh(true)
                }
            },
            immediate: true,
        },
    },
    data() {
        const formatDraftId = (id) => {
            if (!id) return []
            return JSON.parse(id)
        }
        return {
            // 查询参数
            queryParam: { approvalTab: 1, documentType: 1 },
            // 表头
            // columns: defaultColumns,
            // 加载数据方法 必须为 Promise 对象
            loadData: (parameter) => {
                if (this.approvalTab === 3) {
                    const obj = Object.assign(parameter, this.queryParam)
                    return documentBillAuditedPage({ pageNo: obj.pageNo, pageSize: obj.pageSize })
                        .then((res) => {
                            const data = { ...res.data }
                            data.rows = data.rows.map((ele) => {
                                ele.draftId = formatDraftId(ele.draftId)
                                return ele
                            })
                            return data
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
                return documentBillAuditPage(Object.assign(parameter, this.queryParam)).then((res) => {
                    const data = { ...res.data }
                    data.rows = data.rows.map((ele) => {
                        ele.draftId = formatDraftId(ele.draftId)
                        return ele
                    })
                    return data
                })
            },
        }
    },
    methods: {
        singleDelete({ id }) {
            delDocumentBill([
                {
                    id,
                },
            ])
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('删除成功')
                        this.$refs.table.refresh(true)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        edit(record) {
            this.$router.push({ path: '/fileStreamDocumentManage', query: { id: record.id } })
        },
        senpi({ approvalId, nodeId, id }) {
            this.$router.push({ name: 'mobileApproval', query: { id, approvalId, nodeId } })
        },
        see(record) {
            this.$router.push({ name: 'showMobileApproval', query: { id: record.id } })
        },
        Filter(arr, s) {
            // eslint-disable-next-line eqeqeq
            const values = arr.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        onSearch() {
            this.$refs.table.refresh(true)
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .s-table-tool {
    margin-bottom: 0;
}
.mobileTable {
    padding: 15px 12px 12px;
    .tableContainer {
        box-shadow: 0px 1px 8px 2px rgba(80, 80, 80, 0.19);
        border-radius: 3px;
        padding: 6px;
        margin-top: 10px;
        .operatorContent {
            margin-bottom: 15px;
        }
        .bts {
            margin-right: 10px;
        }
        .fred {
            color: #f56c6c;
        }
    }
}
</style>
